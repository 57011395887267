import React, { useEffect, useRef, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CoreButton from "../../common/Button/CoreButton";
import { CustomDropdown } from "../../common/CustomDropdown";
import BasicEditor from "../../common/Editor/BasicEditor";
import AsteriskIcon from "../../common/Icons/AsterickIcon";
import Personalized from "../../common/Personalized/Personalized";
import { insertPersonalizedTag } from "../../helpers/utils/insertPersonalizedTag";
import CoreTextField from "../../common/TextField/CoreTextField";
import { SCRIPT_SETTING_PERSONALIZED_TAGS } from "../../helpers/constant/Constants";
import {
  PERMISSION_LEVELS,
  PERMISSION_MODULES,
  PRIVACY_LEVEL,
  PRIVACY_LEVEL_OPTION,
  PRIVACY_LEVEL_OPTION_FOR_AGENT,
  PRIVACY_LEVEL_VALUE,
} from "../../helpers/constant/CoreConstants";
import { addGeneralScriptInsurance } from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";
import { useGetAllPersonalizedTagsQuery } from "../../state/features/common/personalizedTag/personalizedTagApiSlice";

const AddNewGeneralScript = ({ onCancel }) => {
  const [personalizedTags, setPersonalizedTags] = useState(null);
  const { handleSubmit, setValue, control } = useForm();
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const [error, setError] = useState({
    title: "",
    script: "",
  });
  const {
    generalScripts: { isAdding, generalScriptActiveTab },
  } = useSelector(selectDefaultCallSetting);
  const isUserManageGlobalItem = window.hasPermission(PERMISSION_MODULES.MANAGE_GLOBAL_ITEMS, PERMISSION_LEVELS.WRITE);
  const [privacyType, setPrivacyType] = useState(
    !isUserManageGlobalItem
      ? PRIVACY_LEVEL.PRIVATE
      : generalScriptActiveTab === PRIVACY_LEVEL_VALUE.PRIVATE
      ? PRIVACY_LEVEL.PRIVATE
      : PRIVACY_LEVEL.GLOBAL
  );
  const { data: personalizedQueryTags = [], isSuccess } = useGetAllPersonalizedTagsQuery();

  useEffect(() => {
    let filteredTags = personalizedQueryTags.filter((eachGroup) => eachGroup.isCustomField === true);
    setPersonalizedTags([...SCRIPT_SETTING_PERSONALIZED_TAGS, ...filteredTags]);
  }, [isSuccess]);

  const validateForm = (data) => {
    const errors = {};
    const title = data?.title?.trim();
    const script = data?.script?.trim();

    if (!title) {
      errors.title = "Script title is required";
    }

    if (!script) {
      errors.script = "Script is required";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };
  const onSubmit = (data) => {
    if (!validateForm(data)) {
      console.log(error);
      return;
    }
    const payload = {
      title: data.title,
      description: data.description,
      script: data.script,
      privacy_level: privacyType,
    };
    dispatch(
      addGeneralScriptInsurance({
        payload: payload,
        callback: () => {
          onCancel();
        },
      })
    );
  };

  return (
    <Box component='form' noValidate autoComplete='off' px={4} py={2} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={"row"} spacing={2} mb={0.5}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='title'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script title
                  <AsteriskIcon />
                </Typography>
                <CoreTextField
                  fullWidth
                  required
                  autoFocus
                  value={value}
                  onChange={onChange}
                  type={"text"}
                  size={"small"}
                  placeholder={"Script title here"}
                  inputProps={{ maxlength: 30 }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />
              </>
            )}
          />
        </Stack>
        <Stack spacing={1} sx={{ width: "100%" }}>
          <Controller
            control={control}
            name='description'
            defaultValue=''
            render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
              <>
                <Typography variant={"body2medium"} color={"text.primary"}>
                  Script description
                </Typography>
                <CoreTextField
                  type={"text"}
                  size={"small"}
                  value={value}
                  onChange={onChange}
                  fullWidth
                  multiline
                  minRows={1}
                  maxRows={3}
                  placeholder={"Script description here"}
                  defaultValue={""}
                  inputProps={{ maxlength: 255 }}
                  sx={{ "& .MuiOutlinedInput-root": { padding: "7px 14px" } }}
                  error={invalid}
                  helperText={invalid && error?.message}
                />{" "}
              </>
            )}
          />
        </Stack>
      </Stack>
      <Stack spacing={1} mb={1} sx={{ width: "100%" }}>
        <Typography variant={"body2medium"} color={"text.primary"}>
          Select Script Type
          <AsteriskIcon />
        </Typography>
        <CustomDropdown
          name='scriptType'
          size='medium'
          data={!isUserManageGlobalItem ? PRIVACY_LEVEL_OPTION_FOR_AGENT : PRIVACY_LEVEL_OPTION}
          value={privacyType}
          onChange={(e) => setPrivacyType(e.target.value)}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant={"body2medium"} color={"text.primary"} mt={1.5}>
          {"Write Script"}
          <AsteriskIcon />
        </Typography>

        <Controller
          control={control}
          name='script'
          defaultValue=''
          render={({ field: { value }, fieldState: { invalid, error } }) => (
            <>
              <BasicEditor
                ref={editorRef}
                autoFocus={false}
                body={value}
                onChangeBody={(value) => setValue("script", value, { shouldValidate: true })}
                placeholder='Enter script'
              />
              {invalid && (
                <Typography color='error.main' variant='helperText'>
                  {error?.message}
                </Typography>
              )}
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Personalized
                  data={personalizedTags}
                  onClick={(personalizedTag) =>
                    insertPersonalizedTag(personalizedTag, editorRef, (value) =>
                      setValue("script", value, { shouldValidate: true })
                    )
                  }
                />
                <Stack direction={"row"} alignItems={"center"} spacing={2}>
                  <CoreButton variant={"outlined"} color={"error"} size={"small"} onClick={onCancel}>
                    Cancel
                  </CoreButton>
                  <CoreButton
                    type='submit'
                    variant={"contained"}
                    color={"success"}
                    size={"small"}
                    sx={{ "&.MuiButton-sizeSmall": { padding: "5px 10px" } }}
                    disabled={isAdding ? true : false}
                  >
                    {isAdding ? "Saving" : "Save"} Script
                  </CoreButton>
                </Stack>
              </Stack>
            </>
          )}
        />
      </Stack>
    </Box>
  );
};

export default AddNewGeneralScript;
