import { Box, styled } from "@mui/material";

export const StylesDropDownBox = styled(Box)(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.other.outlinedBorderDarker}`,
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxSizing: "borderBox",
  padding: "4px",
  "&.fbpCustomDropDownInnerFlex": {
    position: "relative",
    borderRadius: "4px",
    width: "220px",
    "&.fbpCustomDropDownWrSmall": {
      padding: "4px",
    },
  },
  "& .fbpCustomDropDownWrMedium": {
    ".MuiSelect-select": {
      padding: "10px 12px",
      fontFamily: "var(--fbp-theming-font-family) !important",
    },
  },
  "& .fbpCustomDropDownWrSmall": {
    "& MuiSelect-select": {
      padding: "6px 12px",
      fontsize: "14px",
      fontFamily: "var(--fbp-theming-font-family) !important",
    },
  },
  "& .fbpCustomDropDownWrLarge": {
    "& MuiSelect-select": {
      padding: "14px 12px",
      fontsize: "14px",
      fontFamily: "var(--fbp-theming-font-family) !important",
    },
  },
  "&.fbpCustomDropDownTopWrLeft": {
    display: "flex",
    alignItems: "center",
    gridGap: "12px",
    "& .fbpCustomDropDownTopTitle": {
      padding: "0",
    },
  },
}));
