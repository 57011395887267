import { List, Paper, Stack, Typography } from "@mui/material";
import EachGeneralScript from "./EachGeneralScript";
import { selectScriptSetting } from "../../state/features/listSetting/listSettingSelector";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";
import { handleSelectGeneralScriptForList } from "../../state/features/listSetting/scriptSetting/scriptSettingSlice";
import { handleSelectDefaultSettingForList } from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";

const GeneralScriptList = ({
  scripts,
  scriptsIds,
  defaultSetting = false,
  preview = false,
  isLoading = false,
  listSetting = false,
  handleOnScroll = () => {},
}) => {
  const dispatch = useDispatch();
  const {
    settings: { selectedIds: listSettingIds },
  } = useSelector(selectScriptSetting);
  const {
    generalScripts: { selectedScriptsIds: selectedScriptsIds },
  } = useSelector(selectDefaultCallSetting);

  const handleCheck = (item, value) => {
    if (listSetting) {
      if (value) {
        let ids = [...listSettingIds];
        ids.push(item.id);
        dispatch(handleSelectGeneralScriptForList(ids));
      } else {
        let ids = listSettingIds.filter((each) => each !== item.id);
        dispatch(handleSelectGeneralScriptForList(ids));
      }
    } else if (defaultSetting) {
      if (value) {
        let ids = [...selectedScriptsIds];
        ids.push(item.id);
        dispatch(handleSelectDefaultSettingForList(ids));
      } else {
        let ids = selectedScriptsIds.filter((each) => each !== item.id);
        dispatch(handleSelectDefaultSettingForList(ids));
      }
    }
  };

  const renderList = () => {
    const view = [];

    scripts.forEach((script, index) => {
      if (!scriptsIds?.includes(script.id)) {
        view.push(
          <EachGeneralScript
            key={script.id}
            script={script}
            index={index}
            defaultSetting={defaultSetting}
            listSetting={listSetting}
            handleCheck={(value) => {
              handleCheck(script, value);
            }}
            preview={preview}
            values={listSetting ? [...listSettingIds] : defaultSetting ? [...selectedScriptsIds] : []}
          />
        );
      }
    });

    if (view.length === 0) {
      return (
        <Stack alignItems={"center"} justifyContent={"center"} py={3}>
          <Typography variant={"body2"} color={"text.primary"}>
            No scripts Found
          </Typography>
        </Stack>
      );
    }

    return view;
  };

  const renderView = () => {
    if (isLoading) {
      return (
        <Stack alignItems={"center"} justifyContent={"center"} py={3}>
          <Typography variant={"body2"} color={"text.primary"}>
            Please wait...
          </Typography>
        </Stack>
      );
    }
    if (scripts.length === 0) {
      return (
        <Stack alignItems={"center"} justifyContent={"center"} py={3}>
          <Typography variant={"body2"} color={"text.primary"}>
            No scripts Found
          </Typography>
        </Stack>
      );
    }
    return (
      <>
        <List
          sx={{ py: 0, maxHeight: "calc(100vh - 370px)" }}
          className={"pdad-scroll-bar"}
          onScroll={(e) => handleOnScroll(e)}
        >
          {renderList()}
        </List>
      </>
    );
  };

  return (
    <Paper elevation={0} sx={{ bgcolor: "action.actionHover", overflow: "hidden", mb: 2, mx: "auto", width: "80%" }}>
      {renderView()}
    </Paper>
  );
};

export default GeneralScriptList;
