import { Box, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";
import {
  addCallScriptForDefault,
  toggleGeneralScriptInsurancePreview,
  toggleGeneralScriptPreview,
} from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import GeneralScriptList from "../GeneralScript/GeneralScriptList";
import CoreButton from "../../common/Button/CoreButton";
const GeneralScriptInsuranceIndex = ({ handleOnScroll, refreshCallScript }) => {
  const dispatch = useDispatch();
  const {
    generalScripts: { data: scriptList, isLoading: isGeneralScriptLoading, selectedScriptsIds: selectedValues },
    callScripts: { isAdding: isCallScriptsAdding, scriptsIds },
  } = useSelector(selectDefaultCallSetting);

  const handleSave = () => {
    dispatch(
      addCallScriptForDefault({
        payload: selectedValues,
        callback: () => {
          refreshCallScript();
          dispatch(toggleGeneralScriptPreview(false));
        },
      })
    );
  };

  return (
    <Stack direction={"column"} justifyContent={"space-between"} px={4} py={2} height={"100%"}>
      <GeneralScriptList
        scripts={scriptList}
        scriptsIds={scriptsIds}
        defaultSetting={true}
        isLoading={isGeneralScriptLoading}
        isAdding={isCallScriptsAdding}
        handleOnScroll={handleOnScroll}
      />

      <Box>
        <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
          <CoreButton
            variant='outlined'
            size='large'
            color={"error"}
            sx={{ minWidth: "max-content" }}
            onClick={() => {
              dispatch(toggleGeneralScriptInsurancePreview(false));
            }}
            disabled={isCallScriptsAdding}
          >
            Back Button
          </CoreButton>
          <CoreButton
            variant='contained'
            size='large'
            color={"secondary"}
            sx={{ minWidth: "max-content" }}
            onClick={handleSave}
            disabled={isCallScriptsAdding || scriptList.length === 0 || selectedValues.length === 0}
          >
            {isCallScriptsAdding ? "adding" : "Add"}
          </CoreButton>
        </Stack>
      </Box>
    </Stack>
  );
};
export default GeneralScriptInsuranceIndex;
