export const STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETE: 2,
  FAILED: 3,
};

export const CALL_START_FROM_LEFT = 1;
export const CALL_START_FROM_BEGINNING = 2;

export const HISTORY_STATE = {
  IMPORT_CONTACT_PATH: "/import-contacts",
  CSV_POPUP: "import-csv-contacts",
  CSV_POWER_DIALER_REFERER: "import-csv-from-power-dialer",
};

export const AGENCY_BUSINESS_TYPE = {
  INSURANCE: "INSURANCE",
  REGULAR: "REGULAR",
};

export const PRIVACY_LEVEL = {
  GLOBAL: "GLOBAL",
  PRIVATE: "PRIVATE",
};

export const PRIVACY_LEVEL_VALUE = {
  GLOBAL: 0,
  PRIVATE: 1,
};

export const PRIVACY_LEVEL_OPTION_FOR_AGENT = [{ title: "My Scripts", value: "PRIVATE" }];
export const PRIVACY_LEVEL_OPTION = [
  { title: "Global Scripts", value: "GLOBAL" },
  { title: "My Scripts", value: "PRIVATE" },
];

export const PERMISSION_MODULES = {
  MANAGE_BILLING: 0,
  MANAGE_USERS: 1,
  MANAGE_GLOBAL_ITEMS: 2,
  EXPORT_CONTACTS: 3,
  EDIT_LEAD_OWNER: 4,
  MANAGE_OTHER_USER_GOALS: 5,
  MANAGE_OTHER_USER_REPORTS: 6,
  MANAGE_OTHER_USER_CALENDAR: 7,
  MANAGE_OTHER_USER_SCHEDULER: 8,
};

export const PERMISSION_LEVELS = {
  READ: 2,
  WRITE: 4,
  DELETE: 8,
};
