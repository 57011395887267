import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Box, List, Stack, styled, Typography } from "@mui/material";
import DialerListItem from "./DialerListItem";
import CreateDialer from "./CreateDialer";
import DialerListSkeleton from "../../common/LoadingView/DialerListSkeleton";
import ShowSideBarIcon from "../../common/Icons/ShowSideBarIcon";
import HideSideBarIcon from "../../common/Icons/HideSideBarIcon";
import { getDialerList, getMoreDialerList, onSelectDialerList } from "../../state/features/dialerList/dialerListSlice";
import { selectDialerListState } from "../../state/features/dialerList/dialerListSelector";
import Styles from "../PowerDialer/DialerBody.module.scss";
import { HistoryProvider } from "../../App";

const CustomList = styled(List)(({ theme }) => ({
  height: "calc(100% - 108px)",
  overflowY: "auto",
  padding: 0,
  paddingRight: "6px",
  "& li": {
    padding: "8px 10px",
    borderRadius: "8px",
    color: theme.palette.text.primary,
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.action.selected,
    },
    "&.active": {
      background: theme.palette.action.selected,
    },
  },
}));

const DialerList = ({ toggleLeftSide, isHideLeftSide, isShowLeftSideTab }) => {
  const history = useContext(HistoryProvider);
  const dispatch = useDispatch();
  const {
    data: dialerList,
    isLoading,
    currentPage,
    limit,
    count,
    selectedDialerList,
  } = useSelector(selectDialerListState);
  const { id: selectedDialerId } = selectedDialerList || {};

  const [isReadyToShow, setReadyToShow] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    dispatch(getDialerList({ currentPage: 1, limit: limit }));
    setReadyToShow(true);
  }, []);

  useEffect(() => {
    if (dialerList && dialerList[0]) {
      let dialerData = dialerList[0];
      console.log(history.location.state, "history.location.state");
      if (history.location.state && history.location.state.powerDialerListId) {
        dialerData =
          dialerList.find((dialer) => dialer.id === parseInt(history.location.state.powerDialerListId)) ||
          dialerList[0];
      }
      dispatch(onSelectDialerList(dialerData));
    }
  }, [dialerList]);

  const fetchMore = () => {
    dispatch(getMoreDialerList({ currentPage: currentPage + 1, limit: limit }));
  };

  useEffect(() => {
    if (count > 0) {
      const has = Math.ceil(count / limit) > currentPage;
      setHasMore(has);
    } else {
      setHasMore(false);
    }
  }, [count, currentPage]);

  // decide what to render
  let dialerListView = null;
  if (isLoading || !isReadyToShow) {
    dialerListView = (
      <CustomList className='pdad-scroll-bar'>
        <DialerListSkeleton count={20} />
      </CustomList>
    );
  } else if (!isLoading && dialerList?.length >= 0) {
    dialerListView = (
      <CustomList
        className='pdad-scroll-bar'
        sx={{ opacity: `${isHideLeftSide ? 0 : 1}`, transitionDelay: "0.3s" }}
        id={"dialerListScrollBar"}
      >
        <InfiniteScroll
          dataLength={dialerList.length}
          next={fetchMore}
          hasMore={hasMore}
          loader={<DialerListSkeleton count={3} />}
          scrollableTarget='dialerListScrollBar'
        >
          {dialerList.map((dialerData) => (
            <DialerListItem key={dialerData.id} dialerData={dialerData} selected={dialerData.id === selectedDialerId} />
          ))}
        </InfiniteScroll>
      </CustomList>
    );
  }

  return (
    <>
      <Box className={`${Styles.leftSidebar} ${isHideLeftSide ? Styles.hideSidebar : ""}`} mt={0.5}>
        <Stack
          direction='row'
          alignItems='center'
          sx={{ justifyContent: "space-between", pr: isHideLeftSide ? 2 : 1, pt: 1, pb: 0.5 }}
        >
          <Typography
            variant='buttonMedium'
            color='text.secondary'
            className={Styles.leftSidebarTitle}
            sx={{ lineHeight: "20px", pl: 1.5 }}
          >
            My Lists
          </Typography>
          <div className={Styles.ToggleSidebar} onClick={toggleLeftSide}>
            {isHideLeftSide ? <ShowSideBarIcon /> : <HideSideBarIcon />}
          </div>
        </Stack>
        <Box
          px={1}
          sx={{
            height: `${isShowLeftSideTab ? "calc(100% - 60px) " : "calc(100vh - 236px)"}`,
            opacity: `${isHideLeftSide ? 0 : 1}`,
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <CreateDialer />
          {dialerListView}
        </Box>
      </Box>
    </>
  );
};

export default DialerList;
