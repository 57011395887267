import React, { useEffect, useState } from "react";
import { Box, Typography, Tabs, Tab, Stack } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { selectDefaultCallSetting } from "../../state/features/listDefaultSetting/defaultListSettingSelector";

import {
  getGeneralScripts,
  getScriptForDefaultSetting,
  handleGeneralScriptTab,
  hasMoreGeneralScripts,
} from "../../state/features/listDefaultSetting/defaultCallSetting/defaultCallSettingSlice";
import CoreButton from "../../common/Button/CoreButton";
import { PRIVACY_LEVEL, PRIVACY_LEVEL_VALUE } from "../../helpers/constant/CoreConstants";
import BasicDrawer from "../../common/Drawer/BasicDrawer";
import AddNewGeneralScript from "./AddNewGeneralScript";
import GeneralScriptInsuranceIndex from "./GeneralScriptInsuranceIndex";

const GeneralScriptInsurance = () => {
  const {
    generalScripts: { page, perPage, search, isLoading, hasMore, generalScriptActiveTab },
  } = useSelector(selectDefaultCallSetting);

  const [openAddScriptModal, setOpenAddScriptModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getGeneralScripts({
        limit: perPage,
        page: 1,
        search: search,
        privacy_level:
          generalScriptActiveTab === PRIVACY_LEVEL_VALUE.PRIVATE ? PRIVACY_LEVEL.PRIVATE : PRIVACY_LEVEL.GLOBAL,
      })
    );
  }, [generalScriptActiveTab]);

  const handleTabChange = (event, newValue) => {
    dispatch(handleGeneralScriptTab(newValue));
  };

  const getCallScript = () => {
    dispatch(getScriptForDefaultSetting({ page: 1, limit: 50 }));
  };

  const handleScroll = (e) => {
    console.log("hasMore: " + hasMore, "isLoading: " + isLoading);
    if (
      hasMore &&
      !isLoading &&
      Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)
    ) {
      console.log("2nd");

      dispatch(
        hasMoreGeneralScripts({
          limit: perPage,
          page: page,
          search: search,
        })
      );
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Stack direction='row' justifyContent='space-between' spacing={2} mb={2}>
        <Box>
          <Box display='flex' alignItems='center' gap='16px'>
            <Box width='60px' height='60px'>
              <img
                src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/call_disposition_icon.png`}
                alt='logo'
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
            <Box>
              <Typography variant='h5' color='text.primary'>
                General Scripts
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Add pre-written scripts to help agents navigate live conversations.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Stack direction='column' alignItems='flex-end' justifyContent='flex-end' spacing={2}>
          <CoreButton
            variant='contained'
            size='medium'
            color='secondary'
            startIcon={<Add />}
            onClick={() => setOpenAddScriptModal(true)}
            sx={{ minWidth: "max-content", width: "155px" }}
          >
            create script
          </CoreButton>
        </Stack>
        {openAddScriptModal && (
          <BasicDrawer
            open={openAddScriptModal}
            toggleDrawer={() => setOpenAddScriptModal(false)}
            title={"Add New Script"}
          >
            <AddNewGeneralScript onCancel={() => setOpenAddScriptModal(false)} />
          </BasicDrawer>
        )}
      </Stack>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs
          value={generalScriptActiveTab}
          onChange={handleTabChange}
          aria-label='Scripts Tabs'
          TabIndicatorProps={{ style: { backgroundColor: "var(--light_blue)" } }}
        >
          <Tab
            label='Global Scripts'
            sx={{ color: "var(--dark_blue)", "&.Mui-selected": { color: "var(--light_blue)" } }}
          />
          <Tab
            label='My Scripts'
            sx={{ color: "var(--dark_blue)", "&.Mui-selected": { color: "var(--light_blue)" } }}
          />
        </Tabs>
      </Box>

      <GeneralScriptInsuranceIndex handleOnScroll={(e) => handleScroll(e)} refreshCallScript={getCallScript} />
    </Box>
  );
};

export default GeneralScriptInsurance;
